import { css } from '@emotion/react';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';
import { margin } from '@prototyp/gatsby-plugin-gumball/utils/margin';

export const root = css`
  height: 100%;
  grid-gap: 32px;
  position: relative;
  padding-bottom: calc(var(--unit) * 15);

  @media ${breakpoints.medium} {
    display: grid;
    grid-gap: 144px;
    grid-template-columns: 1fr 1fr;
  }
`;

export const label = css`
  font-size: var(--font-size-small);
  color: hsl(var(--color-grayscale-6));
  margin: 0;
  margin-bottom: calc(var(--unit) * 4);
  margin-top: calc(var(--unit) * 12);

  @media ${breakpoints.medium} {
    margin-top: 0;
  }
`;
export const labelError = css`
  ${label};
  color: hsl(var(--color-error-7));
`;

export const expertise = css`
  max-width: 100%;
  min-width: 0;
`;

export const expertiseFormInput = css`
  margin: calc(var(--unit) * 3) 0 var(--unit) 0;
  background: transparent;
  font-size: var(--font-size-xxxLarge);
  width: 100%;
  border: none;
  outline: none;
  font-family: var(--font-family-secondary);
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.5px;
  padding-right: calc(var(--unit) * 16);
  color: hsl(var(--color-text-1));
`;

export const expertiseForm = css`
  position: relative;
  border-bottom: 2px solid hsl(var(--color-borderLight-8));
  padding-left: 48px;

  @media ${breakpoints.large} {
    padding-left: 0;
  }
`;

export const expertiseFormError = css`
  ${expertiseForm};
  border-color: hsl(var(--color-error-7));
`;

export const expertiseFormSubmit = css`
  position: absolute;
  right: 0;
  bottom: calc(var(--unit) * 3);
  padding: var(--unit) calc(var(--unit) * 2);
  font-size: var(--font-size-xsmall);
  font-weight: var(--font-weight-bold);
  color: hsl(var(--color-text-1));
  border-radius: 3px;
  background: hsl(var(--color-grayscale-2));
  border: none;
  outline: none;
  width: calc(var(--unit) * 14);
  height: calc(var(--unit) * 10);

  &:hover {
    cursor: pointer;
  }
`;

export const expertiseFormHash = css`
  position: absolute;
  bottom: 14px;
  font-size: var(--font-size-xLarge);
  line-height: var(--line-height-xLarge);
  color: hsl(var(--color-textLight-7));
  font-family: Neue Machina;
  font-style: normal;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;

  @media ${breakpoints.large} {
    left: auto;
    right: calc(100% + 12px);
  }
`;

export const chosenExpertise = css`
  margin-top: calc(var(--unit) * 3);
`;
export const chosenList = css`
  list-style: none;
  padding: 0;
  margin: calc(var(--unit) * 3) 0;
  max-width: 100%;
  overflow: auto;
  display: inline-block;
`;

export const chosenListItem = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: calc(var(--unit) * 2) calc(var(--unit) * 4);
  background: hsl(var(--color-robin-2));
  margin-bottom: calc(var(--unit) * 3);
  border-radius: 3px;
`;

export const chosenText = css`
  ${margin.none}
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
  color: hsl(var(--color-textLight-1));
  font-weight: bold;
  text-overflow: ellipsis;
  max-width: calc(var(--unit) * 50);
`;

export const chosenRemove = css`
  width: calc(var(--unit) * 6);
  min-width: calc(var(--unit) * 6);
  height: calc(var(--unit) * 6);
  border-radius: 50%;
  background: hsl(var(--color-grayscale-1));
  border: none;
  outline: none;
  padding: var(--unit);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: calc(var(--unit) * 4);

  &:hover {
    cursor: pointer;
  }
`;

export const minus = css`
  width: 10px;
  border: 1px solid hsl(var(--color-border-8));
`;

export const suggested = css`
  margin-top: calc(var(--unit) * 4);
  max-height: calc(var(--unit) * 114);
  overflow: auto;
  width: 100%;
  background: hsl(var(--color-grayscale-1));

  &::-webkit-scrollbar {
    width: var(--unit);
  }

  &::-webkit-scrollbar-thumb {
    background: hsl(var(--color-grayscale-2));
    border-radius: var(--unit);
  }
`;

export const suggestedItem = css`
  margin-bottom: calc(var(--unit) * 3);
`;

export const suggestedText = css`
  ${margin.none}
  font-size: var(--font-size-large);
  line-height: var(--line-height-large);
  font-family: var(--font-family-secondary);
  font-weight: normal;
  color: hsl(var(--color-textLight-7));
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media ${breakpoints.medium} {
    font-size: var(--font-size-xxLarge);
    line-height: var(--line-height-xxLarge);
  }

  &:hover {
    cursor: pointer;
    color: hsl(var(--color-robin-3));
  }
`;

export const suggestedResults = css`
  ${margin.none}
  font-size:var(--font-size-tiny);
  line-height: var(--line-height-tiny);
  color: hsl(var(--color-textLight-7));
`;

export const maxExpertise = css`
  margin-top: calc(var(--unit) * 10);
`;

export const chosen = css`
  min-width: 0;
`;
